import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const InfoCircleIcon = ({ viewBox = "0 0 26 26", ...props }: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox={viewBox}>
            <circle cx="13" cy="13" r="13" fill="#0C186C" />
            <path d="M11.6292 19.9384V10.4181H14.3708V19.9384H11.6292ZM11.8734 8.78442C11.5604 8.47146 11.4039 8.0959 11.4039 7.65776C11.4039 7.21961 11.5604 6.84405 11.8734 6.53109C12.1863 6.21813 12.5619 6.06165 13 6.06165C13.4382 6.06165 13.8137 6.21813 14.1267 6.53109C14.4397 6.84405 14.5961 7.21961 14.5961 7.65776C14.5961 8.0959 14.4397 8.47146 14.1267 8.78442C13.8137 9.09739 13.4382 9.25387 13 9.25387C12.5619 9.25387 12.1863 9.09739 11.8734 8.78442Z" fill="white" />
        </SvgIcon>
    );
}
