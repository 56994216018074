import { Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormNumberField } from 'framework/forms/FormNumberField';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { ICargoModel, IRequest, requestsCommand_setCargo } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { BackButton } from 'shared/buttons/BackButton';
import { FormNextSubmitButton } from 'shared/buttons/FormSubmitButton';
import { FormGridContainer } from 'shared/components/FormGridContainer';
import { IconAndH3 } from 'shared/components/IconAndH3';
import { InfoCircleIcon } from 'shared/icons/InfoCircleIcon';
import * as yup from 'yup';
import { FormGridItem } from '../../../shared/components/FormGridItem';
import { Page } from '../Page';

const createSchema = (strings: IStrings): yup.SchemaOf<ICargoModel> => {
    return yup.object<Record<keyof ICargoModel, yup.AnySchema>>({
        description: yup.string().required(),
        lengthInCm: yup.number().required(),
        widthInCm: yup.number().required(),
        heightInCm: yup.number().required(),
        weightInKg: yup.number().required(),
    }).defined();
}

const EmptyValues: ICargoModel = {
    description: '',
    heightInCm: undefined as any,
    lengthInCm: undefined as any,
    weightInKg: undefined as any,
    widthInCm: undefined as any,
}

const itemToModel = (item: IRequest): ICargoModel => {
    if (item.cargoModel) {
        return item.cargoModel;
    } else {
        return ({ ...EmptyValues, description: item.vavatoDescription ?? ''});
    }
} 

interface IProps {
    item: IRequest;
    step: number;
    onNext: () => void;
    onPrevious: () => void;
    onGotoStep: (step: number) => void;
}

export const Cargo = ({ item, step, onNext, onPrevious, onGotoStep }: IProps) => {
    const strings = useLocalization();
    const [submit, isSubmitting] = useFormSubmit(requestsCommand_setCargo);

    const handleSubmit = async (values: ICargoModel, helpers: FormikHelpers<ICargoModel>) => {
        const r = await submit(item.id, values);
        if (handleFormResponse(r, helpers)) {
            onNext();
        }
    }

    return (
        <Formik<ICargoModel>
            validateOnMount
            initialValues={itemToModel(item)}
            validationSchema={createSchema(strings)}
            onSubmit={handleSubmit}>
            <Form className="v100 h100">
                <Page
                    item={item}
                    step={step}
                    onGotoStep={onGotoStep}
                    footer={
                        <div className="df-row-ac jc-sb">
                            <BackButton onClick={onPrevious} />
                            <FormNextSubmitButton isSubmitting={isSubmitting} />
                        </div>
                    }>
                    <div className="df-col">
                        <Typography variant="h2">{strings.dimensions}</Typography>
                        <IconAndH3
                            icon={<InfoCircleIcon />}
                            title={strings.details} />
                        <FormGridContainer>
                            <FormGridItem>
                                <FormNumberField<ICargoModel>
                                    name="lengthInCm"
                                    label={strings.lengthBracketsCm}
                                    suffix=" cm"
                                    required />
                            </FormGridItem>
                            <FormGridItem>
                                <FormNumberField<ICargoModel>
                                    name="widthInCm"
                                    label={strings.widthBracketsCm}
                                    suffix=" cm"
                                    required />
                            </FormGridItem>
                            <FormGridItem>
                                <FormNumberField<ICargoModel>
                                    name="heightInCm"
                                    label={strings.heightBracketsCm}
                                    suffix=" cm"
                                    required />
                            </FormGridItem>
                            <FormGridItem>
                                <FormNumberField<ICargoModel>
                                    name="weightInKg"
                                    label={strings.weigthBracketsKg}
                                    suffix=" kg"
                                    required />
                            </FormGridItem>
                            <FormGridItem>
                                <FormTextField<ICargoModel>
                                    name="description"
                                    label={strings.description}
                                    multiline
                                    rows={4}
                                    required />
                            </FormGridItem>
                        </FormGridContainer>
                    </div>
                </Page>
            </Form>
        </Formik>
    );
}