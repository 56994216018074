import { nlFrameworkStrings } from 'framework/localization/nlFrameworkStrings';
import { IStrings } from './IStrings';

export const nlStrings: IStrings = {
    ...nlFrameworkStrings,
    address: `Adres`,
    areYouSureYouWantToStartOverQuestion: `Ben je zeker dat je opnieuw wilt beginnen?`,
    back: `Terug`,
    backToLotPage: `Terug naar lot pagina`,
    calculateNewTransport: `Bereken een nieuw transport`,
    checkAddresses: `Controleer adressen`,
    city: `Gemeente`,
    companyName: `Bedrijfsnaam`,
    confirmRequest: `Bevestig aanvraag`,
    contactData: `Contactgegevens`,
    content: `Inhoud`,
    country: `Land`,
    date: `Datum`,
    dateAndTime: `Datum & Tijd`,
    description: `Omschrijving`,
    dimensions: `Afmetingen`,
    doYouHaveDimensionsQuestion: `Heb je afmetingen van het te vervoeren goed?`,
    edit: `Aanpassen`,
    emailAddress: `E-mailadres`,
    errorCalculatingPriceCaption: `Het is niet mogelijk de prijs voor je transport te berekenen.\nControleer of het laad- en losadres correct is geregistreerd.`,
    errorCalculatingPriceTitle: `Fout berekenen prijs`,
    euroPallet: `Europallet`,
    euroPallets: `Europallets`,
    extraInfoDescription: `Extra info (busnummer, verdieping, …)`,
    firstName: `Voornaam`,
    from: `Van`,
    getStarted: `Ga van start`,
    heightBracketsCm: `Hoogte (cm)`,
    info: `Info`,
    inVat: `incl. BTW`,
    lastName: `Achternaam`,
    lengthBracketsCm: `Lengte (cm)`,
    lengthWidthHeightShort: `L X B X H`,
    load: `Laden`,
    max: `Max`,
    next: `Verder`,
    no: `Nee`,
    noInfoYet: `Er is nog geen info`,
    number: `Nummer`,
    optional: `Optioneel`,
    pallets: `Pallets`,
    personOnSite: `Naam van persoon ter plaatse`,
    phoneNumber: `Telefoonnummer`,
    price: `Prijs`,
    priceCaption: `Dit is slechts een indicatieve prijs!\nVul onderaan je contactgegevens in en we nemen asap contact met je op.`,
    savedForLater: `Bewaard voor later!`,
    savedForLaterCaption: `We hebben je een e-mail gestuurd met daarin een link om terug te keren naar deze pagina.`,
    saveForLater: `Bewaar voor later`,
    selectADate: `Selecteer een datum`,
    selectedVehicle: `Geselecteerd voertuig`,
    selectTypeVehicle: `Selecteer een type voertuig`,
    startOver: `Opnieuw beginnen`,
    street: `Straat`,
    thanksForYourRequest: `Bedankt, we hebben jouw aanvraag goed ontvangen!`,
    thanksForYourRequestToEmailCaption: (email: string | number) => `We hebben jouw aanvraag naar <b>${email}</b> gestuurd.\nContacteer ons via info@top-speed.be als je verder wilt gaan met deze aanvraag.`,
    time: `Tijd`,
    typeVehicle: `Type voertuig`,
    unload: `Lossen`,
    until: `Tot`,
    vatNumber: `BTW-nummer`,
    weigthBracketsKg: `Gewicht (kg)`,
    welcomeCaption: `Bereken snel de prijs van je transport met onze calculator. Let op: deze prijs is indicatief en niet bindend. Eens je alle info hebt ingevuld en verzonden, nemen we zo snel mogelijk contact op.`,
    welcomeText: `Welkom op de calculator van Top-Speed!`,
    widthBracketsCm: `Breedte (cm)`,
    yes: `Ja`,
    yourTenderNumberIsWhat: (what: string | number) => `Jouw offertenummer is <u>${what.toString().toLowerCase()}</u>`,
    yourTransport: `Jouw transport`,
    yourTransportWillCostApproximatelyWhat: (what: string | number) => `Je transport zal rond de ${what.toString().toLowerCase()} (incl. BTW) kosten.`,
    zip: `Postcode`,
}
