import { format as formatDateFns } from "date-fns";
import { DefaultDateAndTimeFormat } from "./DefaultDateAndTimeFormat";
import { DefaultDateFormat } from "./DefaultDateFormat";

export function formatDate(date: Date | undefined | null, options: 'only-date' | 'date-and-time' = 'only-date'): string {
    if (date === undefined || date === null) {
        return '';
    } else if (date instanceof Date) {
        return formatDateFns(date, options === 'only-date' ? DefaultDateFormat : DefaultDateAndTimeFormat);
    } else {
        return formatDateFns(new Date(date), options === 'only-date' ? DefaultDateFormat : DefaultDateAndTimeFormat);
    }
}