import { Typography } from '@mui/material';
import { FormSelectCountryField } from 'app/countries/FormSelectCountryField';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IContactWithAddressModel, IRequest, requestsCommand_setLoad } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { BackButton } from 'shared/buttons/BackButton';
import { FormNextSubmitButton } from 'shared/buttons/FormSubmitButton';
import { FormGridContainer } from 'shared/components/FormGridContainer';
import { IconAndH3 } from 'shared/components/IconAndH3';
import { InfoCircleIcon } from 'shared/icons/InfoCircleIcon';
import { LocationCircleIcon } from 'shared/icons/LocationCircleIcon';
import * as yup from 'yup';
import { FormGridItem } from '../../../shared/components/FormGridItem';
import { Page } from '../Page';

const createSchema = (strings: IStrings, mustValidateNumber: boolean): yup.SchemaOf<IContactWithAddressModel> => {
    return yup.object<Record<keyof IContactWithAddressModel, yup.AnySchema>>({
        personOnSite: yup.string().required(strings.formRequired(strings.personOnSite)),
        country: yup.string().required(strings.formRequired(strings.country)),
        city: yup.string().required(strings.formRequired(strings.city)),
        street: yup.string().required(strings.formRequired(strings.street)),
        zip: yup.string().required(strings.formRequired(strings.zip)),
        number: mustValidateNumber ? yup.string().required(strings.formRequired(strings.number)) : yup.string().nullable(),
        extraInfo: yup.string().nullable(),
        phoneNumber: yup.string().nullable(),
        companyName: yup.string().nullable(),
    }).defined();
}

const EmptyValues: IContactWithAddressModel = {
    city: '',
    companyName: '',
    country: 'BE',
    extraInfo: '',
    number: '',
    personOnSite: '',
    phoneNumber: '',
    street: '',
    zip: ''
}

interface IProps {
    item: IRequest;
    step: number;
    onNext: () => void;
    onGotoStep: (step: number) => void;
}

export const Load = ({ item, step, onNext, onGotoStep }: IProps) => {
    const strings = useLocalization();
    const [submit, isSubmitting] = useFormSubmit(requestsCommand_setLoad);
    const schema = useMemo<yup.SchemaOf<IContactWithAddressModel>>(() => createSchema(strings, item.hasLoadModelFromVavato === false), [strings, item]);

    const handleSubmit = async (values: IContactWithAddressModel, helpers: FormikHelpers<IContactWithAddressModel>) => {
        const r = await submit(item.id, values);
        if (handleFormResponse(r, helpers)) {
            onNext();
        }
    }

    return (
        <Formik<IContactWithAddressModel>
            validateOnMount
            initialValues={item.loadModel ?? EmptyValues}
            validationSchema={schema}
            onSubmit={handleSubmit}>
            {(props) => (
                <Form className="v100 h100">
                    <Page
                        item={item}
                        step={step}
                        onGotoStep={onGotoStep}
                        footer={
                            <div className="df-row-ac jc-sb">
                                <BackButton disabled />
                                <FormNextSubmitButton isSubmitting={isSubmitting} />
                            </div>
                        }>
                        <div className="df-col">
                            <Typography variant="h2">{strings.load}</Typography>
                            <IconAndH3
                                icon={<InfoCircleIcon />}
                                title={strings.info} />
                            <FormGridContainer>
                                <FormGridItem>
                                    <FormTextField<IContactWithAddressModel>
                                        name="personOnSite"
                                        label={strings.personOnSite}
                                        required />
                                </FormGridItem>
                                <FormGridItem>
                                    <FormTextField<IContactWithAddressModel>
                                        name="phoneNumber"
                                        label={strings.phoneNumber} />
                                </FormGridItem>
                                <FormGridItem>
                                    <FormTextField<IContactWithAddressModel>
                                        name="companyName"
                                        label={strings.companyName} />
                                </FormGridItem>
                            </FormGridContainer>
                            <IconAndH3
                                icon={<LocationCircleIcon />}
                                title={strings.address} />
                            <FormGridContainer>
                                <FormGridItem>
                                    <div className="df-row">
                                        <FormTextField<IContactWithAddressModel>
                                            name="street"
                                            label={strings.street}
                                            required
                                            style={{ width: '70% ' }} />
                                        <FormTextField<IContactWithAddressModel>
                                            name="number"
                                            label={strings.number}
                                            required
                                            style={{ width: '30%', marginLeft: 16 }} />
                                    </div>
                                </FormGridItem>
                                <FormGridItem>
                                    <div className="df-row">
                                        <FormTextField<IContactWithAddressModel>
                                            name="zip"
                                            label={strings.zip}
                                            required
                                            style={{ marginRight: 16, width: '30%' }} />
                                        <FormTextField<IContactWithAddressModel>
                                            name="city"
                                            label={strings.city}
                                            required
                                            style={{ width: '70%' }} />
                                    </div>
                                </FormGridItem>
                                <FormGridItem>
                                    <FormSelectCountryField<IContactWithAddressModel>
                                        name="country"
                                        label={strings.country}
                                        required />
                                </FormGridItem>
                                <FormGridItem>
                                    <FormTextField<IContactWithAddressModel>
                                        name="extraInfo"
                                        label={strings.extraInfoDescription} />
                                </FormGridItem>
                            </FormGridContainer>
                        </div>
                    </Page>
                </Form>
            )}
        </Formik>
    );
}