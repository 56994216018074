import { Button, Typography } from '@mui/material';
import { IRequest } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { BackButton } from 'shared/buttons/BackButton';
import { Page } from '../Page';


interface IProps {
    item: IRequest;
    step: number;
    confirm: (hasDimensions: boolean) => void;
    onPrevious: () => void;
    onGotoStep: (step: number) => void;
}

export const CargoMode = ({ item, step, confirm, onPrevious, onGotoStep }: IProps) => {
    const strings = useLocalization();

    return (
        <Page
            item={item}
            step={step}
            onGotoStep={onGotoStep}
            footer={
                <div className="df-row-ac jc-sb">
                    <BackButton onClick={onPrevious} />
                </div>
            }>
            <div className="df-col-ac h100 jc-c">
                <Typography variant="h3" style={{ marginBottom: 16, textAlign: 'center' }}>{strings.doYouHaveDimensionsQuestion}</Typography>
                <div className="df-row-ac">
                    <Button variant="outlined" style={{ marginRight: 8, width: 120 }} onClick={() => confirm(true)}>{strings.yes}</Button>
                    <Button variant="outlined" style={{ marginLeft: 8, width: 120 }} onClick={() => confirm(false)}>{strings.no}</Button>
                </div>
            </div>
        </Page>
    );
}