import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const PriceCircleIcon = ({ viewBox = "0 0 26 26", ...props }: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox={viewBox}>
            <circle cx="13" cy="13" r="13" fill="#0C186C" />
            <path d="M18.293 7.1894V10.0605C17.5248 9.4355 16.6328 9.123 15.6172 9.123C14.3412 9.123 13.3906 9.65035 12.7656 10.705H16.2422V12.4629H12.2187C12.1927 12.7754 12.1797 13.0097 12.1797 13.166C12.1797 13.4134 12.1862 13.5957 12.1992 13.7129H16.2422V15.4707H12.7461C13.332 16.4473 14.2565 16.9356 15.5195 16.9356C16.5873 16.9356 17.5117 16.6165 18.293 15.9785V18.8692C17.4597 19.3249 16.444 19.5528 15.2461 19.5528C13.8529 19.5528 12.6484 19.1882 11.6328 18.459C10.6302 17.7168 9.92707 16.7207 9.52342 15.4707H7.707V13.7129H9.19138C9.17836 13.5827 9.17185 13.3939 9.17185 13.1465C9.17185 12.834 9.17836 12.6061 9.19138 12.4629H7.707V10.705H9.50389C9.90753 9.35087 10.6237 8.30269 11.6523 7.56049C12.681 6.8183 13.8464 6.4472 15.1485 6.4472C16.4245 6.4472 17.4727 6.6946 18.293 7.1894Z" fill="white" />
        </SvgIcon>
    );
}
