import CancelIcon from '@mui/icons-material/Cancel';
import DatePicker from '@mui/lab/DatePicker';
import { DatePickerView } from '@mui/lab/DatePicker/shared';
import { Fade, InputAdornment, TextField, Typography } from '@mui/material';
import { useField } from 'formik';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { DateIcon } from 'shared/icons/DateIcon';
import { useAnchorElement } from '../hooks/useAnchorElement';
import { useStateBoolean } from '../hooks/useStateBool';
import { DefaultDateFormat } from '../utils/date/DefaultDateFormat';
import { mapToIsoOnlyDate } from '../utils/date/mapToIsoOnlyDate';
import { nameOf } from '../utils/nameOf';
import { FormErrorHelper } from './common/FormErrorHelper';
import { IFormProps } from './common/IFormProps';
import { StaticDatePickerPopover } from './common/StaticDatePickerPopover';

interface IProps<TModel> extends IFormProps<TModel> {
    disableFuture?: boolean;
    disablePast?: boolean;
    openTo?: DatePickerView;
    views?: DatePickerView[];
    format?: string;
}

export const FormDatePicker = <TModel extends unknown>({ style, disableFuture = false, disablePast = false, disabled = false, required = false, helperText, openTo = "day", views = ["year", "month", "day"], format = DefaultDateFormat, ...props }: IProps<TModel>) => {
    const strings = useLocalization();
    const [field, meta, helpers] = useField<Date | null>(nameOf(props.name));
    const [anchor, openSelectDate, closeSelectDate] = useAnchorElement();
    const [isHovered, enter, leave] = useStateBoolean(false);

    return (
        <div className="df-col" style={style}>
            {Boolean(anchor) &&
                <StaticDatePickerPopover
                    value={field.value}
                    setValue={helpers.setValue}
                    anchor={anchor}
                    close={closeSelectDate}
                    openTo={openTo}
                    views={views} />
            }
            <DatePicker
                // label={props.label}
                disabled={disabled}
                {...field}
                disableFuture={disableFuture}
                disablePast={disablePast}
                clearable
                value={field.value === undefined ? null : field.value} // workaround for not handling undefined values BUT handling null values
                onChange={(date) => helpers.setValue(mapToIsoOnlyDate(date))}
                inputFormat={format}
                openTo={openTo}
                views={views}
                renderInput={rProps =>
                    <div className="df-col" style={{ marginTop: 24 }}>
                        <div className="df-row-ac jc-sb" style={{ marginBottom: 8 }}>
                            <Typography variant="body1">{props.label}</Typography>
                            {required === false && <Typography variant="body1" color="textSecondary">{strings.optional}</Typography>}
                        </div>
                        <TextField
                            {...rProps}
                            onMouseEnter={enter}
                            onMouseLeave={leave}
                            className="fg1"
                            variant="outlined"
                            // sx={{ paddingLeft: 0 }}
                            // style={{ paddingLeft: 0}}
                            helperText=""
                            required={required}
                            error={Boolean(meta.error) && meta.touched}
                            onBlur={() => helpers.setTouched(true, true)}
                            inputProps={{
                                ...rProps.inputProps,
                                style: { ...rProps.inputProps!.style, width: 'calc(100% - 80px)', paddingLeft: '10px' }
                            }}
                            InputProps={{
                                startAdornment:
                                    <div className="df-col-ac jc-c" style={{
                                        backgroundColor: '#EAEAEA',
                                        width: '48px',
                                        height: '48px',
                                        marginLeft: '-14px',
                                        // marginRight: '12px',
                                        borderBottomLeftRadius: '4px',
                                        borderTopLeftRadius: '4px',
                                        cursor: 'pointer'
                                    }} onClick={openSelectDate}>
                                        <DateIcon />
                                    </div>,
                                // <InputAdornment position="start">
                                //     <DateIcon style={{}} />
                                // </InputAdornment>,
                                // <div className="stretch-ver stretch-hor df-col" style={{ border: '1px solid black '}}>
                                //     <DateIcon style={{ }} />
                                // </div>,
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Fade in={isHovered && field.value !== undefined && field.value !== null && disabled === false}>
                                            <CancelIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => helpers.setValue(null)} />
                                        </Fade>
                                        {/* <IconButton edge="end" disabled={disabled} onClick={openSelectDate}><InsertInvitationIcon /></IconButton> */}
                                    </InputAdornment>
                            }} />
                    </div>
                } />
            <FormErrorHelper meta={meta} withDate helperText={helperText} />
        </div>
    );
}

