import { IFrameworkStrings } from "framework/localization/IFrameworkStrings"
import { TimeUnit } from "framework/forms/TimeUnit"
import { ITimeUnitTranslation } from "../timeUnit/ITimeUnitTranslation"

export const createTimeUnitRecord = (strings: IFrameworkStrings): Record<TimeUnit, ITimeUnitTranslation> => {
    return {
        Seconds: { singular: strings.second, plural: strings.seconds, unknown: strings.secondOrSeconds },
        Minutes: { singular: strings.minute, plural: strings.minutes, unknown: strings.minuteOrMinutes },
        Hours: { singular: strings.hour, plural: strings.hours, unknown: strings.hourOrHours },
        Days: { singular: strings.day, plural: strings.days, unknown: strings.dayOrDays },
        Weeks: { singular: strings.week, plural: strings.weeks, unknown: strings.weekOrWeeks },
        Months: { singular: strings.month, plural: strings.months, unknown: strings.monthOrMonths },
        Years: { singular: strings.year, plural: strings.years, unknown: strings.yearOrYears },
    }
}