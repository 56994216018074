import { Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormTimePicker } from 'framework/forms/FormTimePicker';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IRequest, ITimeModel, requestsCommand_setLoadTime } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { BackButton } from 'shared/buttons/BackButton';
import { FormNextSubmitButton } from 'shared/buttons/FormSubmitButton';
import { FormGridContainer } from 'shared/components/FormGridContainer';
import { IconAndH3 } from 'shared/components/IconAndH3';
import { DateCircleIcon } from 'shared/icons/DateCircleIcon';
import { TimeCircleIcon } from 'shared/icons/TimeCircleIcon';
import * as yup from 'yup';
import { FormGridItem } from '../../../shared/components/FormGridItem';
import { Page } from '../Page';

const createSchema = (strings: IStrings): yup.SchemaOf<ITimeModel> => {
    return yup.object<Record<keyof ITimeModel, yup.AnySchema>>({
        date: yup.date().required(strings.formRequired(strings.date)),
        afterDate: yup.date().nullable(),
        beforeDate: yup.date().nullable(),
    }).defined() as any;
}

interface IProps {
    item: IRequest;
    step: number;
    onNext: () => void;
    onPrevious: () => void;
    onGotoStep: (step: number) => void;
}

export const LoadTime = ({ item, step, onNext, onPrevious, onGotoStep }: IProps) => {
    const strings = useLocalization();
    const [submit, isSubmitting] = useFormSubmit(requestsCommand_setLoadTime);

    const handleSubmit = async (values: ITimeModel, helpers: FormikHelpers<ITimeModel>) => {
        console.log(values);
        const r = await submit(item.id, values);
        if (handleFormResponse(r, helpers)) {
            onNext();
        }
    }

    return (
        <Formik<ITimeModel>
            validateOnMount
            initialValues={item.loadTime ?? { date: new Date(), after: null, before: null }}
            validationSchema={createSchema(strings)}
            onSubmit={handleSubmit}>
            {(props) => (
                <Form className="v100 h100">
                    <Page
                        item={item}
                        step={step}
                        onGotoStep={onGotoStep}
                        footer={
                            <div className="df-row-ac jc-sb">
                                <BackButton onClick={onPrevious} />
                                <FormNextSubmitButton isSubmitting={isSubmitting} />
                            </div>
                        }>
                        <div className="df-col">
                            <Typography variant="h2">{strings.dateAndTime}</Typography>
                            <IconAndH3
                                icon={<DateCircleIcon />}
                                title={strings.date} />
                            <FormGridContainer>
                                <FormGridItem>
                                    <FormDatePicker<ITimeModel>
                                        name="date"
                                        label={strings.selectADate}
                                        required />
                                </FormGridItem>
                            </FormGridContainer>
                            <IconAndH3
                                icon={<TimeCircleIcon />}
                                title={strings.time} />
                            <FormGridContainer>
                                <FormGridItem>
                                    <FormTimePicker<ITimeModel>
                                        name="afterDate"
                                        label={strings.from} />
                                </FormGridItem>
                                <FormGridItem>
                                    <FormTimePicker<ITimeModel>
                                        name="beforeDate"
                                        label={strings.until} />
                                </FormGridItem>
                            </FormGridContainer>
                        </div>
                    </Page>
                </Form>
            )}
        </Formik>
    );
}