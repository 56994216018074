import { Autocomplete, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useField } from 'formik';
import { FormControlX } from 'framework/forms/common/FormControlX';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { nameOf } from 'framework/utils/nameOf';
import React from 'react';
import { ICountry } from './ICountry';
import { NlCountries } from './NlCountries';

interface IProps<TModel> extends IFormProps<TModel> {

}

export const FormSelectCountryField = <TModel extends unknown>({ ...props }: IProps<TModel>) => {
    const [field, meta, helpers] = useField<string>(nameOf(props.name));

    const onChange = (value: ICountry | null) => {
        helpers.setTouched(true);
        if (value === null || value === undefined) {
            helpers.setValue('');
        } else {
            helpers.setValue(value.code);
        }
    }

    return (
        <FormControlX
            meta={meta}
            {...props}>
            <Autocomplete
                // id="country-select-demo"
                // sx={{ width: 300 }}
                options={NlCountries}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                        />
                        {option.label} ({option.code})
                    </Box>
                )}
                value={NlCountries.find(t => t.code === field.value)}
                onChange={(_, value) => onChange(value)}
                autoSelect
                renderInput={(params) => (
                    <TextField
                        {...params}
                        // label="Choose a country"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                        InputProps={{ ...params.InputProps, style: { paddingTop: 5, paddingBottom: 5 }}}
                    />
                )}
            />
        </FormControlX>
    );
}