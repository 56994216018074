import { TimePicker } from '@mui/lab';
import { TextField } from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import { nameOf } from '../utils/nameOf';
import { FormControlX } from './common/FormControlX';
import { IFormProps } from './common/IFormProps';

interface IProps<TModel> extends IFormProps<TModel> {

}

export const FormTimePicker = <TModel extends unknown>({ ...props }: IProps<TModel>) => {
    const [field, meta, helpers] = useField<Date | null | undefined>(nameOf(props.name));

    return (
        <FormControlX
            meta={meta}
            // enter={enter}
            // leave={leave}
            {...props}>
            <TimePicker
                value={field.value === undefined ? null : field.value}
                onChange={(newValue) => helpers.setValue(newValue)}
                renderInput={(params) => <TextField {...params} />}
            />
        </FormControlX>
    );
}