import { Grid, Typography } from '@mui/material';
import { TrucksCacheContext } from 'app/trucks/TrucksCacheContext';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IRequest, ITruckDto, ITruckModel, requestsCommand_setTruck } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useRef } from 'react';
import { BackButton } from 'shared/buttons/BackButton';
import { FormNextSubmitButton } from 'shared/buttons/FormSubmitButton';
import { FormGridContainer } from 'shared/components/FormGridContainer';
import { IconAndH3 } from 'shared/components/IconAndH3';
import { TruckCircleIcon } from 'shared/icons/TruckCircleIcon';
import * as yup from 'yup';
import { FormGridItem } from '../../../shared/components/FormGridItem';
import { Page } from '../Page';
import { TruckCard } from './TruckCard';

const createSchema = (strings: IStrings): yup.SchemaOf<ITruckModel> => {
    return yup.object<Record<keyof ITruckModel, yup.AnySchema>>({
        description: yup.string().required(strings.formRequired(strings.description)),
        truckId: yup.string().required(strings.formRequired(strings.typeVehicle)),
        pictureAttachmentId: yup.string().nullable(),
    }).defined();
}

const EmptyValues: ITruckModel = {
    description: '',
    truckId: undefined as any,
    pictureAttachmentId: undefined as any,
}

const itemToModel = (item: IRequest): ITruckModel => {
    if (item.truckModel) {
        return item.truckModel;
    } else {
        return ({ ...EmptyValues, description: item.vavatoDescription ?? ''});
    }
}

interface IProps {
    item: IRequest;
    step: number;
    onNext: () => void;
    onPrevious: () => void;
    onGotoStep: (step: number) => void;
}

export const Truck = ({ item, step, onNext, onPrevious, onGotoStep }: IProps) => {
    const strings = useLocalization();
    const [submit, isSubmitting] = useFormSubmit(requestsCommand_setTruck);
    const { data, init } = useContext(TrucksCacheContext);

    useEffect(() => {
        init();
    }, [init]);

    const handleSubmit = async (values: ITruckModel, helpers: FormikHelpers<ITruckModel>) => {
        const r = await submit(item.id, values);
        if (handleFormResponse(r, helpers)) {
            onNext();
        }
    }

    return (
        <Formik<ITruckModel>
            validateOnMount
            initialValues={itemToModel(item)}
            validationSchema={createSchema(strings)}
            onSubmit={handleSubmit}>
            <Inner
                item={item}
                step={step}
                onPrevious={onPrevious}
                isSubmitting={isSubmitting}
                trucks={data ?? []}
                onNext={onNext}
                onGotoStep={onGotoStep} />
        </Formik>
    );
}

interface IInnerProps extends IProps {
    isSubmitting: boolean;
    trucks: ITruckDto[];
}

const Inner = ({ item, step, onPrevious, isSubmitting, trucks, onGotoStep }: IInnerProps) => {
    const props = useFormikContext<ITruckModel>();
    const strings = useLocalization();
    const ref = useRef<any>(null);

    const onSelectTruck = (truck: ITruckDto) => {
        setFormValue<ITruckModel>('truckId', truck.id, props);
        ref.current?.scrollIntoView();
    }

    return (
        <Form className="v100 h100">
            <Page
                item={item}
                step={step}
                onGotoStep={onGotoStep}
                footer={
                    <div className="df-row-ac jc-sb">
                        <BackButton onClick={onPrevious} />
                        <FormNextSubmitButton isSubmitting={isSubmitting} />
                    </div>
                }>
                <div className="df-col">
                    <Typography variant="h2">{strings.dimensions}</Typography>
                    <IconAndH3
                        icon={<TruckCircleIcon />}
                        title={strings.selectTypeVehicle}
                        style={{ marginBottom: 18 }} />
                    <Grid container spacing={3}>
                        {trucks.map(t =>
                            <Grid item xs={"auto"} key={t.id}>
                                <TruckCard
                                    item={t}
                                    isSelected={props.values.truckId === t.id}
                                    onClick={() => onSelectTruck(t)} />
                            </Grid>
                        )}
                    </Grid>
                    <div ref={ref}></div>
                    <FormGridContainer>
                        <FormGridItem>
                            <FormTextField<ITruckModel>
                                name="description"
                                label={strings.description}
                                multiline
                                rows={4}
                                required />
                        </FormGridItem>
                    </FormGridContainer>
                </div>
            </Page>
        </Form>
    )
}