import { LanguageOption } from 'gen/ApiClient';
import { SpecificLocalizationContext } from 'localization/SpecificLocalizationContext';
import { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { RequestRoute, StartRoute } from 'routes';
import { Request } from './request/Request';
import { Start } from './Start';

const dummyRecord: Record<LanguageOption, LanguageOption> = {
    en: 'en',
    fr: 'fr',
    nl: 'nl'
}

export const AppSwitch = () => {
    const { replace } = useHistory();
    const location = useLocation();
    const { setLanguage } = useContext(SpecificLocalizationContext);

    useEffect(() => {
        console.log(location);

        if (location.search) {
            const language = dummyRecord[location.search.replace("?", '') as LanguageOption];
            if (language) {
                setLanguage(language);
            }
            replace(location.pathname === '/' ? StartRoute : location.pathname);
        }
        // replace(StartRoute);
        // eslint-disable-next-line
    }, [location]);

    return (
        <Switch>
            <Route exact path={StartRoute} component={Start} />
            <Route path={RequestRoute} component={Request} />
            <Redirect to={StartRoute} />
        </Switch>
    );
}
