import { Button, DialogProps, Typography } from "@mui/material";
import { useLocalization } from "localization/useLocalization";
import { FullScreenIfMobileDialog } from "shared/components/FullScreenIfMobileDialog";

interface IProps extends DialogProps {
    confirm: () => void;
    cancel: () => void;
}

export const RestartDialog = ({ confirm, cancel, ...rest }: IProps) => {
    const strings = useLocalization();
    return (
        <FullScreenIfMobileDialog
            {...rest}
            maxWidth="xl">
            <Typography variant="h2">{strings.startOver}</Typography>
            <div className="wrap" style={{ marginTop: 16, textAlign: 'center', maxWidth: 424 }}>{strings.areYouSureYouWantToStartOverQuestion}</div>
            <div className="df-row-ac jc-c">
                <Button variant="outlined" style={{ marginTop: 24, marginRight: 8, width: 148 }} onClick={cancel}>{strings.cancel}</Button>
                <Button variant="contained" color="secondary" style={{ marginTop: 24, marginLeft: 8, width: 148 }} onClick={confirm}>{strings.yes}</Button>
            </div>
        </FullScreenIfMobileDialog>
    );
}