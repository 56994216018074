import { Button, Typography } from '@mui/material';
import { clearCacheAndReloadApplication } from 'framework/utils/clearCacheAndReloadApplication';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { Logo200 } from './logo/Logo200';

export const MustUpdateWall = () => {
    const strings = useLocalization();
    return (
        <div className="df-col-ac stretch-ver jc-c">
            <Logo200 style={{ marginBottom: '20px' }} />
            <Typography variant="h5">{`Click to continue`}</Typography>
            <Button variant="contained" color="primary" onClick={clearCacheAndReloadApplication}>{strings.update}</Button>
        </div>
    );
}

