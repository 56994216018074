import { Grid, GridProps } from '@mui/material';
import React from 'react';

export const FormGridContainer = (props: GridProps) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            columnSpacing={5}
            {...props} />
    );
}