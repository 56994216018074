import { frFrameworkStrings } from 'framework/localization/frFrameworkStrings';
import { IStrings } from './IStrings';

export const frStrings: IStrings = {
    ...frFrameworkStrings,
    address: `Adresse`,
    areYouSureYouWantToStartOverQuestion: `Êtes-vous sûr de vouloir recommencer?`,
    back: `Arrière`,
    backToLotPage: `Retour aux enchères`,
    calculateNewTransport: `Calculer un nouveau transport`,
    checkAddresses: `Vérifiez les adresses`,
    city: `commune`,
    companyName: `Nom de la compagnie`,
    confirmRequest: `Confirmer la requête`,
    contactData: `Détails du contact`,
    content: `Contenu`,
    country: `Pays`,
    date: `Date`,
    dateAndTime: `Heure`,
    description: `description`,
    dimensions: `Dimensions`,
    doYouHaveDimensionsQuestion: `Avez-vous des dimensions pour bien transporter?`,
    edit: `Ajuster`,
    emailAddress: `Adresse e-mail`,
    errorCalculatingPriceCaption: `Il n'est pas possible de calculer le prix de votre transport.\nControllez si l'adresse de chargement et de fermeture est correctement enregistrée.`,
    errorCalculatingPriceTitle: `Calculer le prix d'erreur`,
    euroPallet: `Euro`,
    euroPallets: `Euro-opérations`,
    extraInfoDescription: `Info supplémentaire (numéro de bus, étage, ...)`,
    firstName: `Prénom`,
    from: `Par`,
    getStarted: `Aller commence`,
    heightBracketsCm: `Hauteur (cm)`,
    info: `Info`,
    inVat: `TVA incl.`,
    lastName: `nom de famille`,
    lengthBracketsCm: `Longueur (cm)`,
    lengthWidthHeightShort: `L x w x h`,
    load: `Charge`,
    max: `Max`,
    next: `Plus loin`,
    no: `non`,
    noInfoYet: `Il n'y a pas encore d'informations`,
    number: `Nombre`,
    optional: `Optionnel`,
    pallets: `Palettes`,
    personOnSite: `Nom de la personne sur place`,
    phoneNumber: `numéro de téléphone`,
    price: `Prix`,
    priceCaption: `Ceci est juste un prix indicatif!\nRemplis au bas de vos coordonnées et nous vous contacterons.`,
    savedForLater: `Gardé pour plus tard!`,
    savedForLaterCaption: `Nous vous avons envoyé un e-mail avec un lien pour revenir à cette page.`,
    saveForLater: `Garder pour plus tard`,
    selectADate: `Sélectionnez une date`,
    selectedVehicle: `Véhicule sélectionné`,
    selectTypeVehicle: `Sélectionnez un type de véhicule`,
    startOver: `Recommencer`,
    street: `Rue`,
    thanksForYourRequest: `Merci, nous avons bien reçu votre demande!`,
    thanksForYourRequestToEmailCaption: (email: string | number) => `Nous avons envoyé votre demande à <b>${email}</b>.\nContactez nous via info@top-speed.be si vous souhaitez continuer cette application.`,
    time: `Temps`,
    typeVehicle: `Type de véhicule`,
    unload: `Décharger`,
    until: `Jusqu'à`,
    vatNumber: `numéro de TVA`,
    weigthBracketsKg: `poids (kg)`,
    welcomeCaption: `Calculez le prix de votre transport avec notre calculatrice rapidement. Veuillez noter que ce prix est indicatif et non contraignant. Une fois que vous avez terminé et envoyé toutes les informations, nous nous contacterons dès que possible.`,
    welcomeText: `Bienvenue dans la calculatrice de la vitesse maximale!`,
    widthBracketsCm: `Largeur (cm)`,
    yes: `Oui`,
    yourTenderNumberIsWhat: (what: string | number) => `Votre citation été est <u> ${what.toString().toLowerCase()}</u>`,
    yourTransport: `Votre transport`,
    yourTransportWillCostApproximatelyWhat: (what: string | number) => `Votre transport coûtera environ ${what.toString().toLowerCase()} (TVA incl.).`,
    zip: `Code postal`,
}
