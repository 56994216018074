import { Button, ButtonProps } from "@mui/material";
import { useLocalization } from "localization/useLocalization";
import { ArrowLeftIcon } from "shared/icons/ArrowLeftIcon";

export const BackButton = (props: ButtonProps) => {
    const strings = useLocalization();

    return (
        <Button variant="text" size="small" startIcon={<ArrowLeftIcon />} style={{ marginLeft: -16 }} {...props}>{strings.back}</Button>
    );
}