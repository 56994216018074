import { createTheme, Theme } from "@mui/material";

const paletteTheme = createTheme({
    palette: {
        primary: {
            main: '#0C186C',
            contrastText: '#fff',
        }, 
        secondary: {
            main: '#D12000',
            contrastText: '#FFFFFF'
        },
        info: {
            main: '#EAEAEA'
        },
        error: {
            main: '#F22525',
        }, 
        success: {
            main: '#00D181'
        },
        text: {
            primary: '#353535',
            secondary: '#9A9A9A',
        },
    },
})

export const theme: Theme = createTheme(paletteTheme, {
    components: {
        MuiLinearProgress: {
            styleOverrides:  {
                root: {
                    height: 8,
                    backgroundColor: 'rgba(0, 209, 129, 0.15)'
                }, 
                bar: {
                    borderRadius: '0px 100px 100px 0px',
                }
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    padding: 16,
                }, 
                sizeMedium: {
                    fontSize: '18px',
                    lineHeight: '18px',
                    fontWeight: 700,

                }, 
                sizeSmall: {
                    fontSize: '16px',
                    lineHeight: '100%',
                    fontWeight: 300,
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    // height: '48px',
                }, 
                input: {
                    paddingTop: '12.5px',
                    paddingBottom: '12.5px'
                }
            }
        },
    },
    typography: {
        fontFamily: "'Hind', sans-serif",
        h2: {
            fontFamily: "'Hind', sans-serif",
            fontWeight: 700,
            fontSize: '28px',
            lineHeight: '28px',
            letterSpacing: 0,
            color: paletteTheme.palette.primary.main,
        },
        h3: { 
            fontFamily: "'Hind', sans-serif",
            fontWeight: 700,
            fontSize: '22px',
            lineHeight: '22px',
            letterSpacing: 0,
            color: paletteTheme.palette.primary.main,
        },
        h4: {
            fontFamily: "'Hind', sans-serif",
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '100%',
            letterSpacing: 0,
            color: paletteTheme.palette.primary.main,
        },
        subtitle1: {
            fontFamily: "'Hind', sans-serif",
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '16px',
            letterSpacing: 0,
            color: paletteTheme.palette.primary.main,
        }, 
        subtitle2: {
            fontFamily: "'Hind', sans-serif",
            fontWeight: 300,
            fontSize: '16px',
            lineHeight: '16px',
            letterSpacing: 0,
        },
        body1: {
            fontFamily: "'Hind', sans-serif",
            fontWeight: 300,
            fontSize: '16px',
            lineHeight: '16px',
            letterSpacing: 0,
        },
        button: {
            fontFamily: "'Hind', sans-serif",
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '14px',
            letterSpacing: 0,
            textTransform: 'none',
            color: paletteTheme.palette.text.secondary,
            cursor: 'pointer'
        }
    },    
});