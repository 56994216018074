import { ButtonProps } from "@mui/material";
import { useFormikContext } from "formik";
import { LoaderButton } from "framework/components/buttons/LoaderButton";
import { useLocalization } from "localization/useLocalization";
import { ArrowRightIcon } from "shared/icons/ArrowRightIcon";

interface IProps extends ButtonProps {
    isSubmitting: boolean;
}

export const FormNextSubmitButton = <TModel extends object>({ isSubmitting, ...rest }: IProps) => {
    const strings = useLocalization();
    const props = useFormikContext<TModel>();
    return (
        <LoaderButton
            variant="contained"
            color="secondary"
            endIcon={<ArrowRightIcon />}
            type="submit"
            onClick={() => props.submitForm()}
            isLoading={isSubmitting}
            {...rest}>
            {strings.next}
        </LoaderButton>
    );
}