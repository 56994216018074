import { AxiosInstance, AxiosRequestConfig } from "axios";
import { apiUrl, languageHeaderKey, languageKey } from "../keys";
import { FrameworkDefaultAxiosInstance } from "framework/xhr/FrameworkDefaultAxiosInstance";

export const DefaultAxiosInstance = (config?: AxiosRequestConfig): AxiosInstance => {
    const instance = FrameworkDefaultAxiosInstance(apiUrl, config);
    // only in debug
    if (process.env.NODE_ENV === 'development') {
        instance.interceptors.request.use(config => {
            config.withCredentials = true;
            return config;
        })
    }
    const json = window.localStorage.getItem(languageKey);
    if (json && instance.defaults.headers) {
        instance.defaults.headers.common[languageHeaderKey] = JSON.parse(json);
    }
    return instance;
}

