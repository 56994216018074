import { ICountry } from "./ICountry";

export const NlCountries: ICountry[] = [
    { code: 'AU', label: 'Australië' },
    { code: 'BE', label: 'België' },
    { code: 'BR', label: 'Brazilië' },
    { code: 'BG', label: 'Bulgarije' },
    { code: 'CA', label: 'Canada' },
    { code: 'CN', label: 'China' },
    { code: 'CY', label: 'Cyprus' },
    { code: 'DK', label: 'Denemarken' },
    { code: 'DE', label: 'Duitsland' },
    { code: 'EE', label: 'Estland' },
    { code: 'FI', label: 'Finland' },
    { code: 'FR', label: 'Frankrijk' },
    { code: 'GE', label: 'Georgië' },
    { code: 'GH', label: 'Ghana' },
    { code: 'GR', label: 'Griekenland' },
    { code: 'HU', label: 'Hongarije' },
    { code: 'IE', label: 'Ierland' },
    { code: 'IS', label: 'IJsland' },
    { code: 'IL', label: 'Israël' },
    { code: 'IT', label: 'Italië' },
    { code: 'JO', label: 'Jordanië' },
    { code: 'HR', label: 'Kroatië' },
    { code: 'LV', label: 'Letland' },
    { code: 'LI', label: 'Liechtenstein' },
    { code: 'LT', label: 'Litouwen' },
    { code: 'LU', label: 'Luxemburg' },
    { code: 'MT', label: 'Malta' },
    { code: 'MX', label: 'Mexico' },
    { code: 'MC', label: 'Monaco' },
    { code: 'NL', label: 'Nederland' },
    { code: 'NO', label: 'Noorwegen' },
    { code: 'UA', label: 'Oekraïne' },
    { code: 'AT', label: 'Oostenrijk' },
    { code: 'PL', label: 'Polen' },
    { code: 'PT', label: 'Portugal' },
    { code: 'RO', label: 'Roemenië' },
    { code: 'RU', label: 'Rusland' },
    { code: 'RS', label: 'Servië' },
    { code: 'SI', label: 'Slovenië' },
    { code: 'SK', label: 'Slowakije' },
    { code: 'ES', label: 'Spanje' },
    { code: 'CZ', label: 'Tsjechië' },
    { code: 'TN', label: 'Tunesië' },
    { code: 'TR', label: 'Turkije' },
    { code: 'GB', label: 'Verenigd Koninkrijk' },
    { code: 'AE', label: 'Verenigde Arabische Emiraten' },
    { code: 'US', label: 'Verenigde Staten' },
    { code: 'ZA', label: 'Zuid-Afrika' },
    { code: 'SE', label: 'Zweden' },
    { code: 'CH', label: 'Zwitserland' },
];