import DateAdapter from '@mui/lab/AdapterDateFns';
import MuiLocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline, ThemeProvider } from '@mui/material';
import frBE from 'date-fns/locale/fr';
import nlBE from 'date-fns/locale/nl-BE';
import { ApiProvider } from 'framework/context/ApiProvider';
import { LoaderProvider } from 'framework/context/LoaderProvider';
import { LocalizationProvider } from 'framework/context/LocalizationProvider';
import { DialogsProvider } from 'framework/dialogs/DialogsProvider';
import { LanguageOption } from 'gen/ApiClient';
import { languageKey } from 'keys';
import { IStrings } from 'localization/IStrings';
import { localizationRecord } from 'localization/localizationRecord';
import { SpecificLocalizationContext } from 'localization/SpecificLocalizationContext';
import { BrowserRouter } from 'react-router-dom';
import { RecordProvider } from 'shared/records/RecordProvider';
import { theme } from '../theme';
import { AppSwitch } from './AppSwitch';
import { AppUpdateWall } from './AppUpdateWall';
import { Loader } from './loader/Loader';
import { SnackbarWrapper } from './SnackbarWrapper';
import { TrucksCacheProvider } from './trucks/TrucksCacheProvider';

const App = () => {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <SnackbarWrapper>
          <BrowserRouter>
            <LoaderProvider loader={<Loader />}>
              <LocalizationProvider<IStrings, LanguageOption> map={localizationRecord} context={SpecificLocalizationContext} localStorageKey={languageKey} fallbackValue="nl">
                <SpecificLocalizationContext.Consumer>
                  {({ strings, language }) => (
                    <MuiLocalizationProvider dateAdapter={DateAdapter} locale={language === 'fr' ? frBE : nlBE}>
                      <RecordProvider>
                        <ApiProvider strings={strings} onRedirect={() => { }} >
                          <AppUpdateWall>
                            <DialogsProvider>
                              <TrucksCacheProvider>
                                <AppSwitch />
                              </TrucksCacheProvider>
                            </DialogsProvider>
                          </AppUpdateWall>
                        </ApiProvider>
                      </RecordProvider>
                    </MuiLocalizationProvider>
                  )}
                </SpecificLocalizationContext.Consumer>
              </LocalizationProvider>
            </LoaderProvider>
          </BrowserRouter>
        </SnackbarWrapper>
      </ThemeProvider>
    </>
  );
}

export default App;