import { Button, Typography } from "@mui/material";
import { Logo200 } from "app/logo/Logo200";
import { IRequest } from "gen/ApiClient";
import { useLocalization } from "localization/useLocalization";
import { useHistory } from "react-router";
import { StartRoute } from "routes";

interface IProps {
    item: IRequest;
}

export const Confirmation = ({ item }: IProps) => {
    const strings = useLocalization();
    const { push } = useHistory();

    const onGoBackToLot = () => {
        if (item.vavatoUrl) {
            window.location.href = item.vavatoUrl;
        }
    }

    return (
        <div className="df-col-ac h100 w100" style={{ paddingTop: 80, paddingBottom: 80, paddingLeft: 20, paddingRight: 20 }}>
            <div className="fg1 fb0 df-row"><Logo200 /></div>
            <div id="request-confirmation-id" className="df-col-ac w100">
                <div style={{ fontSize: '32px' }}>🎉</div>
                <Typography variant="h2" style={{ marginTop: 8 }}>{strings.thanksForYourRequest}</Typography>
                <Typography variant="h2" style={{ marginTop: 8, marginBottom: 8 }}><div dangerouslySetInnerHTML={{ __html: strings.yourTenderNumberIsWhat(item.easyTransOrderId!) }}></div></Typography>
                <div className="wrap" style={{ textAlign: 'center', marginTop: 8 }}
                    dangerouslySetInnerHTML={{ __html: strings.thanksForYourRequestToEmailCaption(item.contactModel?.email ?? 'xxx') }} />
                <Button variant="contained" color="secondary" style={{ marginTop: 16 }} onClick={() => push(StartRoute)}>{strings.calculateNewTransport}</Button>
                {item.isFromVavato &&
                    <Button variant="outlined" style={{ marginTop: 16 }} onClick={onGoBackToLot}>{strings.backToLotPage}</Button>
                }
            </div>
            <div className="fg1 fb0 df-row" style={{ marginRight: 40 }}></div>
        </div>
    );
}