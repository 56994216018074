import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ArrowRightIcon = ({ viewBox = "0 0 12 18", ...props }: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox={viewBox}>
            <path d="M0 16.0895V2.09112C0 1.25079 0.973627 0.785183 1.62775 1.3127L10.96 8.83871C11.4796 9.25772 11.4506 10.0584 10.9021 10.4388L1.5699 16.9112C0.906739 17.3711 0 16.8965 0 16.0895Z" />
        </SvgIcon>
    );
}
