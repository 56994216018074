import { CacheProvider } from "framework/context/CacheProvider";
import { ITruckDto, trucksQuery_all, trucksQuery_concurrencyToken } from "gen/ApiClient";
import React from "react";
import { TrucksCacheContext } from "./TrucksCacheContext";

interface IProps {
    children: any;
}

export const TrucksCacheProvider = ({ children }: IProps) => {
    return (
        <CacheProvider<ITruckDto[]>
            concurrencyF={trucksQuery_concurrencyToken}
            loadF={trucksQuery_all}
            context={TrucksCacheContext}>
            {children}
        </CacheProvider>
    )
}