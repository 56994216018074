import { Box } from '@mui/system';
import { IRequest } from 'gen/ApiClient';
import { useIsMobile } from 'shared/useIsMobile';
import { Header } from './Header';
import { SideBar } from './SideBar';

interface IProps {
    item: IRequest;
    step: number;
    children: any;
    footer: JSX.Element;
    onGotoStep: (step: number) => void;
}

export const Page = ({ item, step, children, footer, onGotoStep }: IProps) => {
    const isMobile = useIsMobile();

    return (
        <div className="df-col h100">
            <Header item={item} step={step} />
            <div className="df-row h100" style={{ overflow: 'hidden' }}>
                <Box className="fg1" style={{ overflowY: 'auto', backgroundColor: '#FCFCFC' }} sx={{ padding: { xs: '10px', sm: '40px' } }}>
                    {children}
                </Box>
                <Box style={{ overflowY: 'auto', minWidth: '400px', boxShadow: '-1px 0px 0px #EAEAEA' }} sx={{ display: { xs: 'none', sm: 'flex' } }}>
                    <SideBar item={item} onGotoStep={onGotoStep} />
                </Box>
            </div>
            <div className="sticky-at-bottom" style={{ boxShadow: '0px -1px 4px rgba(211, 211, 211, 0.25)', paddingLeft: isMobile ? 10 : 40, paddingTop: 16, paddingRight: isMobile ? 10 : 40, paddingBottom: 16 }}>
                {footer}
            </div>
        </div>
    );
}