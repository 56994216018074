// import { RefreshIcon } from 'shared/icons/RefreshIcon';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, LinearProgress, MenuItem, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import { Box } from '@mui/system';
import { Logo130 } from 'app/logo/Logo130';
import { RestartDialog } from 'app/RestartDialog';
import { useDialogsContext } from 'framework/dialogs/useDialogsContext';
import { IRequest, LanguageOptions } from 'gen/ApiClient';
import { SpecificLocalizationContext } from 'localization/SpecificLocalizationContext';
import { useLocalization } from 'localization/useLocalization';
import { useContext, useMemo } from 'react';
import { useHistory } from 'react-router';
import { StartRoute } from 'routes';
import { ArrowRightIcon } from 'shared/icons/ArrowRightIcon';
import { useIsMobile } from 'shared/useIsMobile';

interface IProps {
    item: IRequest;
    step: number;
}

export const Header = ({ item, step }: IProps) => {
    const { open, confirm, cancel } = useDialogsContext();
    const strings = useLocalization();
    const progress = useMemo(() => (step / 6) * 100, [step]);
    const { push } = useHistory();
    const isMobile = useIsMobile();
    const { language, setLanguage } = useContext(SpecificLocalizationContext);

    const onRestart = () => {
        open(<RestartDialog
            open
            confirm={() => {
                confirm();
                push(StartRoute);
            }}
            cancel={cancel} />);
    }

    return (
        <div className="df-col sticky-at-top">
            <div className="df-row-ac jc-sb">
                <div className="fg1 fb0 df-row"><Logo130 style={{ margin: 16, marginLeft: isMobile ? 10 : 40 }} /></div>
                <Box className="df-row-ac" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                    <Typography variant={step === 1 ? 'subtitle1' : 'subtitle2'}>{strings.load}</Typography>
                    <ArrowRightIcon fontSize="small" style={{ marginLeft: 16, marginRight: 16 }} color={step === 1 ? 'primary' : 'info'} />
                    <Typography variant={step === 2 ? 'subtitle1' : 'subtitle2'}>{strings.dateAndTime}</Typography>
                    <ArrowRightIcon fontSize="small" style={{ marginLeft: 16, marginRight: 16 }} color={step === 2 ? 'primary' : 'info'} />
                    <Typography variant={(step === 3 || step === 4) ? 'subtitle1' : 'subtitle2'}>{strings.dimensions}</Typography>
                    <ArrowRightIcon fontSize="small" style={{ marginLeft: 16, marginRight: 16 }} color={(step === 3 || step === 4) ? 'primary' : 'info'} />
                    <Typography variant={step === 5 ? 'subtitle1' : 'subtitle2'}>{strings.unload}</Typography>
                    <ArrowRightIcon fontSize="small" style={{ marginLeft: 16, marginRight: 16 }} color={step === 5 ? 'primary' : 'info'} />
                    <Typography variant={step === 6 ? 'subtitle1' : 'subtitle2'}>{strings.price}</Typography>
                </Box>
                <div className="fg1 fb0 jc-e df-row" style={{ marginRight: isMobile ? 10 : 40 }}>
                    <Select
                        value={language}
                        SelectDisplayProps={{ style: { paddingTop: 0, paddingBottom: 0 }}}
                        style={{ marginRight: 16 }}>
                        {LanguageOptions.map(t => <MenuItem key={t} value={t} onClick={() => setLanguage(t)}>{t.toUpperCase()}</MenuItem>)}
                    </Select>
                    <Button variant="outlined" color="primary" style={{ width: 42, height: 42, minWidth: 0, minHeight: 0 }} onClick={onRestart}>
                        <RefreshIcon />
                    </Button>
                </div>
            </div>
            <LinearProgress color="success" value={progress} variant="determinate" />
        </div>
    );
}