import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const CheckCircleIcon = ({ viewBox = "0 0 26 26", ...props }: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox={viewBox}>
            <circle cx="13" cy="13" r="13" />
            <path d="M7.64907 12.4692C7.06034 11.8863 6.11061 11.8911 5.52777 12.4798C4.94494 13.0686 4.94971 14.0183 5.53843 14.6011L7.64907 12.4692ZM12.0625 18.9492L11.0072 20.0152C11.3162 20.3212 11.7429 20.4779 12.1765 20.4449C12.6101 20.4118 13.008 20.1922 13.2672 19.843L12.0625 18.9492ZM20.6109 9.94453C21.1045 9.27922 20.9653 8.33972 20.3 7.84612C19.6347 7.35251 18.6952 7.49171 18.2016 8.15703L20.6109 9.94453ZM5.53843 14.6011L11.0072 20.0152L13.1178 17.8832L7.64907 12.4692L5.53843 14.6011ZM13.2672 19.843L20.6109 9.94453L18.2016 8.15703L10.8578 18.0555L13.2672 19.843Z" fill="white" />
        </SvgIcon>
    );
}
