import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ArrowLeftIcon = ({ viewBox = "0 0 12 17", ...props }: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox={viewBox}>
            <path d="M12 1.91051L12 15.9089C12 16.7492 11.0264 17.2148 10.3722 16.6873L1.04 9.16129C0.520426 8.74228 0.549376 7.94156 1.09786 7.56116L10.4301 1.0888C11.0933 0.628868 12 1.10347 12 1.91051Z" fill="#EAEAEA" />
        </SvgIcon>
    );
}
