
import { useCallback } from 'react';
import { IApiErrorStrings } from '../localization/apiErrors/IApiErrorString';
import { NotificationType } from '../context/NotificationType';

export function useApiErrorHandling(strings: IApiErrorStrings, redirect: () => void, notify: (message: string, type: NotificationType) => void): (error: any) => void  {
    // eslint-disable-next-line
    const redirectCallback = useCallback(redirect, []);
    // eslint-disable-next-line
    const onNotifyCallback = useCallback(notify, []);
    
    return useCallback((error: any) => {
        if (error) {
            onNotifyCallback(matchError(error, strings), errorToType(error));
            if (needRedirect(error)) {
                redirectCallback();
            }
        }
    }, [onNotifyCallback, redirectCallback, strings]);
}

const needRedirect = (error: any): boolean => {
    const status = error?.response?.status ?? undefined;
    return status && status === 401;
}

const errorToType = (error: any): NotificationType => {
    const status = error?.response?.status ?? undefined;
    return status && (status === 401 || status === 403) 
        ? 'warning'
        : 'error';
}

const matchError = (error: any, strings: IApiErrorStrings): string => {
    const status = error?.response?.status ?? undefined;
    if (error === undefined) {
        return '';
    } else if (status) {
        switch (status) {
            case 401:
                return strings.error401;
            case 403:
                return strings.error403;
            case 500:
                return strings.error500;
            default:
                return strings.errorUnmapped(status);
        }
    } else if (error.toString() === 'Error: Network Error') {
        return strings.errorNoNetwork;
    } else {
        return strings.errorUnknown;
    }
}