import { FormControl, Typography } from '@mui/material';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { FormErrorHelper, IMeta } from './FormErrorHelper';
import { IFormProps } from './IFormProps';

interface IProps<T> extends IFormProps<T> {
    children: any;
    meta: IMeta;
    enter?: () => void;
    leave?: () => void;
}

export const FormControlX = <T extends unknown>({ label, children, meta, enter, leave, helperText, required = false, style, ...rest }: IProps<T>) => {
    const strings = useLocalization();
    return (
        <FormControl
            onMouseEnter={enter}
            onMouseLeave={leave}
            style={{ marginTop: 24, display: 'flex', ...style }}
            {...rest}
            error={meta.error && meta.touched ? true : false}>
            <div className="df-row-ac jc-sb" style={{ marginBottom: 8 }}>
                <Typography variant="body1">{label}</Typography>
                {required === false && <Typography variant="body1" color="textSecondary">{strings.optional}</Typography>}
            </div>
            {children}
            <FormErrorHelper meta={meta} helperText={helperText} />
        </FormControl>
    );
}