import { formatDate } from "framework/utils/date/formatDate";
import { formatDateX } from "framework/utils/date/formatDateX";
import { ITimeModel } from "gen/ApiClient";
import { IStrings } from "localization/IStrings";

export const formatTimeModel = (dt: ITimeModel, strings: IStrings): string => {
    const x = formatDate(dt.date);
    if (dt.afterDate && dt.beforeDate) {
        return `${x}\n${strings.from.toLowerCase()} ${formatDateX(dt.afterDate, 'HH:mm')} ${strings.until.toLowerCase()} ${formatDateX(dt.beforeDate, 'HH:mm')}`;
    } else if (dt.afterDate) {
        return `${x}\n${strings.from.toLowerCase()} ${formatDateX(dt.afterDate, 'HH:mm')}`;
    } else if (dt.beforeDate) {
        return `${x}\n${strings.until.toLowerCase()} ${formatDateX(dt.beforeDate, 'HH:mm')}`;
    } else {
        return x;
    }
}

