import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { useIsMobile } from "shared/useIsMobile";

interface IProps extends DialogProps {
    children: any;
}

export const FullScreenIfMobileDialog = ({ children, ...props }: IProps) => {
    const isMobile = useIsMobile();

    return (
        <Dialog
            {...props}
            fullScreen={isMobile}>
            <DialogContent
                className="df-col-ac jc-c"
                style={{ maxWidth: 677 }}
                sx={{ padding: { xs: '10px', sm: '80px' } }}>
                {children}
            </DialogContent>
        </Dialog>
    );
}