import { enFrameworkStrings } from 'framework/localization/enFrameworkStrings';
import { IStrings } from './IStrings';

export const enStrings: IStrings = {
    ...enFrameworkStrings,
    address: `Address`,
    areYouSureYouWantToStartOverQuestion: `Are you sure you want to start again?`,
    back: `Back`,
    backToLotPage: `Back to auction`,
    calculateNewTransport: `Calculate a new transport`,
    checkAddresses: `Check addresses`,
    city: `Township`,
    companyName: `Company Name`,
    confirmRequest: `Confirm request`,
    contactData: `Contact details`,
    content: `Contents`,
    country: `Country`,
    date: `Date`,
    dateAndTime: `Date Time`,
    description: `Description`,
    dimensions: `Dimensions`,
    doYouHaveDimensionsQuestion: `Do you have dimensions to transport well?`,
    edit: `To adjust`,
    emailAddress: `E-mail address`,
    errorCalculatingPriceCaption: `It is not possible to calculate the price for your transport.\nCheck if the load and unload address are correctly registered.`,
    errorCalculatingPriceTitle: `Calculate error Price`,
    euroPallet: `Europallet`,
    euroPallets: `Europallets`,
    extraInfoDescription: `Extra info (bus number, floor, ...)`,
    firstName: `First Name`,
    from: `By`,
    getStarted: `Go starts`,
    heightBracketsCm: `Height (cm)`,
    info: `Info`,
    inVat: `in. VAT`,
    lastName: `Last name`,
    lengthBracketsCm: `Length (cm)`,
    lengthWidthHeightShort: `L x W x H`,
    load: `Load`,
    max: `Max`,
    next: `Further`,
    no: `new`,
    noInfoYet: `There is no info yet`,
    number: `Number`,
    optional: `Optional`,
    pallets: `Pallets`,
    personOnSite: `Name of Person on the spot`,
    phoneNumber: `phone number`,
    price: `Price`,
    priceCaption: `This is just an indicative price!\nFill your contact details at the bottomand we will contact you.`,
    savedForLater: `Saved for later!`,
    savedForLaterCaption: `We sent you an e-mail with a link to return to this page.`,
    saveForLater: `Save for later`,
    selectADate: `Select a date`,
    selectedVehicle: `Selected vehicle`,
    selectTypeVehicle: `Select a type of vehicle`,
    startOver: `Start over`,
    street: `Street`,
    thanksForYourRequest: `Thank you, we have received your request well!`,
    thanksForYourRequestToEmailCaption: (email: string | number) => `We sent your request to <b>${email}</b>.\nContact us via info@top-speed.be if you want to continue this application.`,
    time: `Time`,
    typeVehicle: `Vehicle type`,
    unload: `Unload`,
    until: `Until`,
    vatNumber: `VAT number`,
    weigthBracketsKg: `Weight (kg)`,
    welcomeCaption: `Calculate the price of your transport with our calculator quickly. Please note: this price is indicative and not binding. Once you have completed and sent all information, we will contact us as soon as possible.`,
    welcomeText: `Welcome to the Calculator of Top Speed!`,
    widthBracketsCm: `Width (cm)`,
    yes: `Yes`,
    yourTenderNumberIsWhat: (what: string | number) => `Your quotation summer is <u>${what.toString().toLowerCase()}</u>`,
    yourTransport: `Your transportation`,
    yourTransportWillCostApproximatelyWhat: (what: string | number) => `Your transport will cost around ${what.toString().toLowerCase()} (in. VAT).`,
    zip: `Postal Code`,
}
