import { Typography } from '@mui/material';
import React from 'react';

interface IProps {
    icon: React.ReactNode;
    title: string;
    style?: React.CSSProperties;
}

export const IconAndH3 = ({ icon, title, style }: IProps) => {
    return (
        <div className="df-row-ac" style={{ marginTop: 42, ...style}}>
            {icon}
            <Typography variant="h3" style={{ marginLeft: 8 }}>{title}</Typography>
        </div>
    );
}