import { Card, CardContent, CardMedia } from "@mui/material";
import { ITruckDto } from "gen/ApiClient";
import { useLocalization } from "localization/useLocalization";
import { useMemo } from "react";
import { CheckCircleIcon } from "shared/icons/CheckCircleIcon";

const selectedStyle: React.CSSProperties = {
    border: '4px solid #00D181',
    // boxShadow: '0px 0px 12px 4px rgba(53, 53, 53, 0.1)',
}

interface IProps {
    item: ITruckDto;
    isSelected: boolean;
    onClick: () => void;
}

export const TruckCard = ({ item, isSelected, onClick }: IProps) => {
    const strings = useLocalization();
    const tStyle = useMemo<React.CSSProperties>(() => isSelected ? selectedStyle : {}, [isSelected]);

    return (
        <Card
            className="h100"
            style={{ cursor: 'pointer', ...tStyle, maxWidth: 224 }}
            onClick={onClick}>
            <CardMedia style={{ marginTop: '-2px', position: 'relative' }}>
                {isSelected && <CheckCircleIcon color="success" style={{ position: 'absolute', top: 8, right: 8 }} />}
                <img src={`data:image/png;base64,${item.base64Image}`} alt={item.name} />
            </CardMedia>
            <CardContent className="df-col" style={{ padding: 8 }}>
                <div style={{ fontSize: '18px', fontWeight: 700 }}>{item.name}</div>
                <LabelValue label={strings.content} value={`${item.volume.toFixed(2)} m³`} />
                <LabelValue label={strings.lengthWidthHeightShort} value={`${item.length.toFixed(2)}m x ${item.width.toFixed(2)}m x ${item.height.toFixed(2)}m`} />
                <LabelValue label={strings.pallets} value={`${item.countEuroPallets.toString()} ${item.countEuroPallets > 1 ? strings.euroPallets : strings.euroPallet}`} />
                <LabelValue label={strings.max} value={`${item.maxLoad.toString()} kg`} />
            </CardContent>
        </Card>
    );
}

const LabelValue = ({ label, value }: { label: string, value: string }) => {
    return (
        <div><b>{`${label}: `}</b>{value}</div>
    );
}