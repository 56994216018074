import { useApiEffect } from 'framework/hooks/useApiEffect';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { requestsQuery_single } from 'gen/ApiClient';
import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { Load } from './1_load/Load';
import { LoadTime } from './2_loadTime/LoadTime';
import { CargoMode } from './3_cargoMode/CargoMode';
import { Cargo } from './4_cargo/Cargo';
import { Truck } from './4_cargo/Truck';
import { Unload } from './5_unload/Unload';
import { Contact } from './6_contact/Contact';
import { Confirmation } from './7_confirmation/Confirmation';
import { pathToStep } from './utils/pathToStep';

export const Request = () => {
    const { id } = useParams<IRouteParamsWithId>();
    const [item, reload] = useApiEffect(requestsQuery_single, id);
    const { url, path } = useRouteMatch();
    const { pathname } = useLocation();
    const step = useMemo(() => pathToStep(pathname), [pathname]);
    const { push, replace } = useHistory();
    const [hasDimensions, setHasDimensions] = useState<boolean>(true);

    useEffect(() => {
        if (item?.isConfirmed === true && step !== 7) {
            push(`${url}/7`);
        }
        // eslint-disable-next-line
    }, [item?.isConfirmed, step, url]);

    useEffect(() => {
        if (item?.hasCargoModelFromVavato === true && step === 3) {
            setHasDimensions(true);
            replace(`${url}/4`);
        }
        // eslint-disable-next-line
    }, [item, step, url, replace]);

    // useEffect(() => {
    //     if (item) {
    //         if (item.hasCargoModel && hasDimensions === false) {
    //             setHasDimensions(true);
    //         } else if (item.hasTruckModel && hasDimensions === true) {
    //             setHasDimensions(false);
    //         }
    //     }
    // }, [item, hasDimensions]);

    const onGotoStep = (step: number) => {
        reload();
        push(`${url}/${step}`);
    }

    const onNext = () => {
        onGotoStep(step + 1);
    }

    const onCargoMode = (hasDimensions: boolean) => {
        setHasDimensions(hasDimensions);
        onNext();
    }

    const onPrevious = () => {
        reload();
        if (step === 4 && item?.hasCargoModelFromVavato) {
            onGotoStep(2);
        } else {
            onGotoStep(step - 1);
        }
    }

    if (item === undefined) {
        return <div></div>
    }

    return (
        <Switch>
            <Route exact path={`${path}/1`} render={() => <Load item={item} step={step} onNext={onNext} onGotoStep={onGotoStep} />} />
            <Route exact path={`${path}/2`} render={() => <LoadTime item={item} step={step} onNext={onNext} onPrevious={onPrevious} onGotoStep={onGotoStep} />} />
            <Route exact path={`${path}/3`} render={() => <CargoMode item={item} step={step} confirm={onCargoMode} onPrevious={onPrevious} onGotoStep={onGotoStep} />} />
            <Route exact path={`${path}/4`} render={() => hasDimensions
                ? <Cargo item={item} step={step} onNext={onNext} onPrevious={onPrevious} onGotoStep={onGotoStep} />
                : <Truck item={item} step={step} onNext={onNext} onPrevious={onPrevious} onGotoStep={onGotoStep} />
            } />
            <Route exact path={`${path}/5`} render={() => <Unload item={item} step={step} onNext={onNext} onPrevious={onPrevious} onGotoStep={onGotoStep} />} />
            <Route exact path={`${path}/6`} render={() => <Contact item={item} step={step} onNext={onNext} onPrevious={onPrevious} onGotoStep={onGotoStep} reload={reload} />} />
            <Route exact path={`${path}/7`} render={() => <Confirmation item={item} />} />
            <Redirect to={`${path}/1`} />
        </Switch>
    );
}