import { FormHelperText } from '@mui/material';
import React, { useMemo } from 'react';
import { useLocalization } from 'localization/useLocalization';
import { theme } from '../../../theme';
import { ErrorTextWithIcon } from './ErrorTextWithIcon';
import { FormHelperTextX } from './FormHelperTextX';

export interface IMeta {
    error?: string | undefined;
    touched: boolean;
    initialError?: string | undefined;
}

interface IProps {
    meta: IMeta;
    withDate?: boolean;
    helperText?: JSX.Element | string;
}

export const FormErrorHelper = ({ meta, withDate = false, helperText }: IProps) => {
    const strings = useLocalization();
    const showError = useMemo(() => ((meta.error && meta.touched) || meta.initialError) ? true : false, [meta.error, meta.touched, meta.initialError]);

    return (
        <>
            {showError &&
                <FormHelperText style={{ marginLeft: '4px', color: theme.palette.error.main }} component="div">
                    <ErrorTextWithIcon error={((meta.error?.indexOf('Invalid Date') ?? -1) > -1 && withDate) ? strings.invalidDateFormat : meta.error} />
                </FormHelperText>}
            {showError === false && helperText && 
                <FormHelperTextX>{helperText}</FormHelperTextX>
            }
        </>  
    );
}