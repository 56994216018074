import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const TimeCircleIcon = ({ viewBox = "0 0 26 26", ...props }: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox={viewBox}>
            <circle cx="13" cy="13" r="13" fill="#0C186C" />
            <path d="M13 6C9.1402 6 6 9.1402 6 13C6 16.8598 9.1402 20 13 20C16.8598 20 20 16.8598 20 13C20 9.1402 16.8598 6 13 6ZM15.3051 16.2949L12.3 13.2898V8.8H13.7V12.7102L16.2949 15.3051L15.3051 16.2949Z" fill="white" />
        </SvgIcon>
    );
}
