import { Button, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { useDialogsContext } from 'framework/dialogs/useDialogsContext';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IContactModel, IRequest, requestsCommand_confirm, requestsCommand_saveForLater } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect } from 'react';
import { BackButton } from 'shared/buttons/BackButton';
import { FormGridContainer } from 'shared/components/FormGridContainer';
import { IconAndH3 } from 'shared/components/IconAndH3';
import { LocationCircleIcon } from 'shared/icons/LocationCircleIcon';
import { PriceCircleIcon } from 'shared/icons/PriceCircleIcon';
import * as yup from 'yup';
import { FormGridItem } from '../../../shared/components/FormGridItem';
import { Page } from '../Page';
import { IncorrectAddressesDialog } from './IncorrectAddressesDialog';
import { SavedForLaterDialog } from './SavedForLaterDialog';

const createSchema = (strings: IStrings): yup.SchemaOf<IContactModel> => {
    return yup.object<Record<keyof IContactModel, yup.AnySchema>>({
        firstName: yup.string().required(strings.formRequired(strings.firstName)),
        lastName: yup.string().required(strings.formRequired(strings.lastName)),
        email: yup.string().required(strings.formRequired(strings.emailAddress)),
        phoneNumber: yup.string().required(strings.formRequired(strings.phoneNumber)),
        vatNumber: yup.string().nullable(),
    }).defined();
}

const EmptyValues: IContactModel = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    vatNumber: ''
}

interface IProps {
    item: IRequest;
    step: number;
    onNext: () => void;
    onPrevious: () => void;
    onGotoStep: (step: number) => void;
    reload: () => void;
}

export const Contact = ({ item, step, onNext, onPrevious, onGotoStep, reload }: IProps) => {
    const strings = useLocalization();
    const [submit, isSubmitting] = useFormSubmit(requestsCommand_confirm);
    const [saveForLater, isSavingForLater] = useFormSubmit(requestsCommand_saveForLater);
    const { open, cancel } = useDialogsContext();

    useEffect(() => {
        if (item.hasRateWarnings) {
            open(<IncorrectAddressesDialog
                open
                close={() => { cancel(); onGotoStep(1); }} />)
        }
        // eslint-disable-next-line
    }, [item]);

    const handleSubmit = async (values: IContactModel, helpers: FormikHelpers<IContactModel>) => {
        const r = await submit(item.id, values);
        if (handleFormResponse(r, helpers)) {
            onNext();
        }
    }

    const onSaveForLater = async (values: IContactModel, helpers: FormikHelpers<IContactModel>) => {
        const r = await saveForLater(item.id, values);
        if (r.isSuccess) {
            open(<SavedForLaterDialog
                open
                close={cancel} />)
        }
    }

    return (
        <Formik<IContactModel>
            validateOnMount
            initialValues={item.contactModel ?? EmptyValues}
            validationSchema={createSchema(strings)}
            onSubmit={handleSubmit}>
            {(props) => (
                <Form className="v100 h100">
                    <Page
                        item={item}
                        step={step}
                        onGotoStep={onGotoStep}
                        footer={
                            <div className="df-row-ac">
                                <BackButton onClick={onPrevious} />
                                <div className='fg1'></div>
                                <div className="df-row-ac">
                                    <Button
                                        variant="outlined"
                                        disabled={props.isValid === false || isSavingForLater} onClick={() => onSaveForLater(props.values, props)}
                                        style={{ marginRight: 16 }}>
                                        {strings.saveForLater}
                                    </Button>
                                    {item.hasPrice &&
                                        <LoaderButton
                                            variant="contained"
                                            color="secondary"
                                            type="submit"
                                            isLoading={isSubmitting}>
                                            {strings.confirmRequest}
                                        </LoaderButton>
                                    }
                                </div>
                            </div>
                        }>
                        <div className="df-col">
                            <Typography variant="h2">{strings.price}</Typography>
                            {(item.hasPrice && item.hasRateWarnings === false)
                                ? <>
                                    <IconAndH3
                                        icon={<PriceCircleIcon />}
                                        title={strings.yourTransportWillCostApproximatelyWhat(formatCurrency(item.price ?? 0))} />
                                    <div className="wrap" style={{ marginTop: 18 }}>{strings.priceCaption}</div>
                                </>
                                : <>
                                    <IconAndH3
                                        icon={<PriceCircleIcon />}
                                        title={strings.errorCalculatingPriceTitle} />
                                    <div className="wrap" style={{ marginTop: 18 }}>{strings.errorCalculatingPriceCaption}</div>
                                </>
                            }
                            <IconAndH3
                                icon={<LocationCircleIcon />}
                                title={strings.contactData} />
                            <FormGridContainer>
                                <FormGridItem>
                                    <FormTextField<IContactModel>
                                        name="firstName"
                                        label={strings.firstName}
                                        required />
                                </FormGridItem>
                                <FormGridItem>
                                    <FormTextField<IContactModel>
                                        name="lastName"
                                        label={strings.lastName}
                                        required />
                                </FormGridItem>
                                <FormGridItem>
                                    <FormTextField<IContactModel>
                                        name="email"
                                        label={strings.emailAddress}
                                        required />
                                </FormGridItem>
                                <FormGridItem>
                                    <FormTextField<IContactModel>
                                        name="phoneNumber"
                                        label={strings.phoneNumber}
                                        required />
                                </FormGridItem>
                                <FormGridItem>
                                    <FormTextField<IContactModel>
                                        name="vatNumber"
                                        label={strings.vatNumber} />
                                </FormGridItem>
                            </FormGridContainer>
                        </div>
                    </Page>
                </Form>
            )}
        </Formik>
    );
}