import { Typography } from "@mui/material";
import { TrucksCacheContext } from "app/trucks/TrucksCacheContext";
import { formatCurrency } from "framework/utils/formatCurrency";
import { IRequest } from "gen/ApiClient";
import { useLocalization } from "localization/useLocalization";
import { useContext, useEffect, useMemo } from "react";
import { formatTimeModel } from "shared/formatTimeModel";

interface IProps {
    item: IRequest;
    onGotoStep: (step: number) => void;
}

export const SideBar = ({ item, onGotoStep }: IProps) => {
    const strings = useLocalization();
    const { data, init } = useContext(TrucksCacheContext);
    const fTruck = useMemo(() => (item.hasSelectedOrAssignedTruckId && data !== undefined) ? data.find(t => t.id === item.selectedOrAssignedTruckId) : undefined, [data, item]);

    useEffect(() => {
        init();
    }, [init]);

    return (
        <div className="df-col" style={{ padding: 40, paddingBottom: 20 }}>
            <Typography variant="h3">{strings.yourTransport}</Typography>
            {item.hasLoadModel === false &&
                <div style={{ marginTop: 11 }}>{strings.noInfoYet}</div>
            }
            {item.hasLoadModel &&
                <>
                    <div className="df-row-ac jc-sb" style={{ marginTop: 24, marginBottom: 8 }}>
                        <Typography variant="h4">{strings.load}</Typography>
                        <Typography variant="button" onClick={() => onGotoStep(1)}>{strings.edit}</Typography>
                    </div>
                    <div className="wrap">{item.formattedLoadModel}</div>
                </>
            }
            {item.hasLoadTimeModel &&
                <>
                    <div className="df-row-ac jc-sb" style={{ marginTop: 24, marginBottom: 8 }}>
                        <Typography variant="h4">{strings.dateAndTime}</Typography>
                        <Typography variant="button" onClick={() => onGotoStep(2)}>{strings.edit}</Typography>
                    </div>
                    <div className="wrap">{formatTimeModel(item.loadTime, strings)}</div>
                </>
            }
            {item.hasCargoModel &&
                <>
                    <div className="df-row-ac jc-sb" style={{ marginTop: 24, marginBottom: 8 }}>
                        <Typography variant="h4">{strings.dimensions}</Typography>
                        <Typography variant="button" onClick={() => onGotoStep(4)}>{strings.edit}</Typography>
                    </div>
                    <div className="wrap">{item.formattedCargoModel}</div>
                    {fTruck &&
                        <>
                            <div className="df-row-ac jc-sb" style={{ marginTop: 24, marginBottom: 8 }}>
                                <Typography variant="h4">{strings.typeVehicle}</Typography>
                            </div>
                            <div className="wrap">{fTruck.display}</div>
                        </>
                    }
                </>
            }
            {item.hasCargoModel === false && item.hasTruckModel &&
                <>
                    <div className="df-row-ac jc-sb" style={{ marginTop: 24, marginBottom: 8 }}>
                        <Typography variant="h4">{strings.selectedVehicle}</Typography>
                        <Typography variant="button" onClick={() => onGotoStep(4)}>{strings.edit}</Typography>
                    </div>
                    {fTruck && <div className="wrap">{fTruck.display}</div>}
                </>
            }
            {item.hasUnloadModel &&
                <>
                    <div className="df-row-ac jc-sb" style={{ marginTop: 24, marginBottom: 8 }}>
                        <Typography variant="h4">{strings.unload}</Typography>
                        {item.hasSelectedOrAssignedTruckId && <Typography variant="button" onClick={() => onGotoStep(5)}>{strings.edit}</Typography>}                        
                    </div>
                    <div className="wrap">{item.formattedUnloadModel}</div>
                </>
            }
            {item.hasPrice &&
                <>
                    <div className="df-row-ac jc-sb" style={{ marginTop: 24, marginBottom: 8 }}>
                        <Typography variant="h4">{`${strings.price} (${strings.inVat})`}</Typography>
                    </div>
                    <div className="wrap">{formatCurrency(item.price!)}</div>
                </>
            }
            {item.hasContactModel &&
                <>
                    <div className="df-row-ac jc-sb" style={{ marginTop: 24, marginBottom: 8 }}>
                        <Typography variant="h4">{strings.contactData}</Typography>
                    </div>
                    <div className="wrap">{item.formattedContactModel}</div>
                </>
            }
            <div className="fg1"></div>
        </div>
    );
}