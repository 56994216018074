import { Button, DialogProps, Typography } from "@mui/material";
import { useLocalization } from "localization/useLocalization";
import { FullScreenIfMobileDialog } from "shared/components/FullScreenIfMobileDialog";

interface IProps extends DialogProps {
    close: () => void;
}

export const IncorrectAddressesDialog = ({ close, ...rest }: IProps) => {
    const strings = useLocalization();

    return (
        <FullScreenIfMobileDialog
            {...rest}
            maxWidth="xl">
            <span role="img" style={{ marginBottom: 8, fontSize: 28 }}>😞</span>
            <Typography variant="h2">{strings.errorCalculatingPriceTitle}</Typography>
            <div className="wrap" style={{ marginTop: 16, textAlign: 'center', maxWidth: 424 }}>{strings.errorCalculatingPriceCaption}</div>
            <Button variant="contained" color="secondary" style={{ marginTop: 24 }} onClick={close}>{strings.checkAddresses}</Button>
        </FullScreenIfMobileDialog>
    );
}