import { OutlinedInput } from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import { useStateBoolean } from '../hooks/useStateBool';
import { nameOf } from '../utils/nameOf';
import { ClearEndAdornment } from './common/ClearEndAdornment';
import { FormControlX } from './common/FormControlX';
import { IFormProps } from './common/IFormProps';

interface IFormTextFieldProps<TModel> extends IFormProps<TModel> {
  placeholder?: string,
  multiline?: boolean,
  rows?: number,
}

export const FormTextField = <TModel extends unknown>({ multiline = false, rows, ...props }: IFormTextFieldProps<TModel>) => {
  const [field, meta, helpers] = useField<string>(nameOf(props.name));
  const [isHovered, enter, leave] = useStateBoolean(false);

  return (
    <FormControlX
      meta={meta}
      enter={enter}
      leave={leave}
      {...props}>
      <OutlinedInput
        rows={rows}
        multiline={multiline}
        placeholder={props.placeholder}
        {...field}
        endAdornment={<ClearEndAdornment isHovered={isHovered} field={field} helpers={helpers} disabled={props.disabled ?? false} defaultValue={''} />}
      />
    </FormControlX>
  );
}