import { Backdrop, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

export const Loader = () => {
    return (
        <Backdrop
            style={{ zIndex: 25000, backgroundColor: 'rgba(0, 0, 0, 0.05' }}
            open={true} >
            <Box sx={{ display: 'flex' }}>
                <CircularProgress style={{ width: 100, height: 100 }} color="secondary" />
            </Box>
            {/* <img src={preloader} alt="loading..." /> */}
        </Backdrop>
    );
}