import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const LocationCircleIcon = ({ viewBox = "0 0 26 26", ...props }: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox={viewBox}>
            <circle cx="13" cy="13" r="13" fill="#0C186C" />
            <path d="M13 20.0622C12.0091 19.217 11.0906 18.2903 10.2541 17.292C8.99886 15.7928 7.50824 13.56 7.50824 11.4323C7.5077 10.3457 7.82951 9.28334 8.43297 8.3797C9.03642 7.47606 9.89438 6.77174 10.8983 6.35589C11.9022 5.94004 13.0069 5.83134 14.0725 6.04356C15.1382 6.25578 16.117 6.77937 16.885 7.54807C17.3963 8.05712 17.8016 8.66253 18.0774 9.32926C18.3532 9.99599 18.494 10.7108 18.4917 11.4323C18.4917 13.56 17.0011 15.7928 15.7459 17.292C14.9094 18.2903 13.9909 19.217 13 20.0622ZM13 9.0787C12.3758 9.0787 11.7771 9.32667 11.3357 9.76806C10.8944 10.2094 10.6464 10.8081 10.6464 11.4323C10.6464 12.0565 10.8944 12.6552 11.3357 13.0966C11.7771 13.5379 12.3758 13.7859 13 13.7859C13.6242 13.7859 14.2229 13.5379 14.6642 13.0966C15.1056 12.6552 15.3536 12.0565 15.3536 11.4323C15.3536 10.8081 15.1056 10.2094 14.6642 9.76806C14.2229 9.32667 13.6242 9.0787 13 9.0787Z" fill="white" />
        </SvgIcon>
    );
}
