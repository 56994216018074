import { enStrings } from "./enStrings";
import { frStrings } from "./frStrings";
import { IStrings } from "./IStrings";
import { nlStrings } from "./nlStrings";
import { LanguageOption } from "gen/ApiClient";

export const localizationRecord: Record<LanguageOption, IStrings> = {
  nl: nlStrings,
  en: enStrings,
  fr: frStrings,
}

