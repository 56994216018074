import React, { useMemo } from 'react';
import { useLocalization } from 'localization/useLocalization';
import { createTimeUnitRecord } from './createTimeUnitRecord';
import { RecordContext } from './RecordContext';

export const RecordProvider = ({ children }: any) => {
    const strings = useLocalization();
    const timeUnitRecord = useMemo(() => createTimeUnitRecord(strings), [strings]);

    return (
        <RecordContext.Provider
            value={{
                timeUnitRecord: timeUnitRecord,
            }}>
            {children}
        </RecordContext.Provider>
    );
};
