import { useDialogsContext } from 'framework/dialogs/useDialogsContext';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { calcRequestRoute } from 'routes';
import { v1 as uuid } from 'uuid';
import { StartDialog } from './StartDialog';

export const Start = () => {
    const { replace } = useHistory();
    const { open, cancel } = useDialogsContext();

    useEffect(() => {
        open(<StartDialog
            open
            close={cancel} />);
        replace(calcRequestRoute(uuid()));
        // eslint-disable-next-line
    }, []);    

    return (<div></div>)
}